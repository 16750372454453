import React from 'react';
import { graphql } from 'gatsby';

// Components
import SeoDetails from '../components/common/seoDetails';
import HeroImage from '../components/common/sections/heroImage';
import Introduction from '../components/common/sections/introduction';
import ItemGrid from '../components/common/sections/itemGrid';
import Carousel from '../components/common/sections/carousel';

const ApartmentsPage = ({ data }) => {
  const { seoContent, contentSections } = data.contentfulPages;
  const apartmentData = data.allContentfulApartments.edges;
  const roomData = data.allContentfulRooms.edges;

  return (
    <>
      <SeoDetails seoContent={seoContent} />
      <HeroImage seoContent={seoContent} />
      <Introduction contentSection={contentSections[0]} />
      <ItemGrid itemData={apartmentData} />
      <Carousel itemData={roomData} contentSection={contentSections[1]} />
    </>
  );
};

export const query = graphql`
  query {
    contentfulPages(id: { eq: "e1488988-78d5-57d9-bf10-ab6fd7458c85" }) {
      id
      title
      seoContent {
        pageTitle
        menuTitle
        slug
        description {
          description
        }
        featuredImage {
          title
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      contentSections {
        title
        subtitle
        text {
          childMarkdownRemark {
            html
          }
        }
        link {
          slug
          pageTitle
          menuTitle
        }
      }
    }
    allContentfulApartments {
      edges {
        node {
          id
          seoContent {
            featuredImage {
              title
              fluid(maxWidth: 2000) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            menuTitle
            pageTitle
            slug
          }
          features {
            id
            title
          }
        }
      }
    }
    allContentfulRooms {
      edges {
        node {
          id
          seoContent {
            featuredImage {
              title
              fluid(maxWidth: 2000) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            menuTitle
            pageTitle
            slug
          }
          features {
            id
            title
          }
        }
      }
    }
  }
`;

export default ApartmentsPage;
